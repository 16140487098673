'use client'

import { useSearchParams } from 'next/navigation'
import { memo, PropsWithChildren, useEffect, useMemo, useState } from 'react'

import { CartContext } from './cart.context'

import { Product } from '@/types/cart'

export const CartProvider = memo(({ children }: PropsWithChildren) => {
  const [cart, setCart] = useState<Product[]>([])
  const [isCartOpen, setIsCartOpen] = useState(false)
  const [table, setTable] = useState<string>()
  const searchParams = useSearchParams()

  const addToCart = (newItem: Product) => {
    const existingItem = cart.find((item) => item.name === newItem.name)
    if (existingItem) {
      setCart(cart.map((item) => (item.name === newItem.name ? newItem : item)))
    } else {
      setCart([...cart, newItem])
    }
  }

  const removeFromCart = (removedItem: Product) => {
    const existingItem = cart.find((item) => item.name === removedItem.name)
    if (existingItem && existingItem.quantity > 1 && removedItem.quantity > 0) {
      setCart(
        cart.map((item) =>
          item.name === removedItem.name ? removedItem : item
        )
      )
    } else {
      setCart(cart.filter((p) => p.name !== removedItem.name))
    }
  }

  const updateQuantity = (item: Product) => {
    const existingItem = cart.find((p) => p.name === item.name)
    if (item.quantity === 0) {
      setCart(cart.filter((p) => p.name !== item.name))
    } else if (existingItem) {
      setCart(cart.map((p) => (p.name === item.name ? item : p)))
    } else {
      setCart([...cart, item])
    }
  }

  useEffect(() => {
    if (searchParams.get('table')) {
      setTable(searchParams.get('table') as string)
    }
  }, [searchParams])

  const contextValue = useMemo(() => {
    return {
      cart,
      isCartOpen,
      table,
      addToCart,
      removeFromCart,
      setIsCartOpen,
      updateQuantity,
    }
  }, [cart, isCartOpen, table])

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  )
})
