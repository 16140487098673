import { Upload } from 'antd'
import { t } from 'i18next'
import React from 'react'

import CSV from '@/assets/icons/csv'
import DOC from '@/assets/icons/doc'
import PDF from '@/assets/icons/pdf'

import { convertToFileList } from '@/utils/upload'

const { Dragger } = Upload

interface DragDropAreaProps {
  setFileList: (fileList: FileList | null) => void
}

const DragDropArea: React.FC<DragDropAreaProps> = ({ setFileList }) => (
  <Dragger
    beforeUpload={() => false}
    onChange={(e) => setFileList(convertToFileList(e.fileList))}
    multiple
    accept='.doc,.docx,.pdf,.csv,.xlsx,.txt'
  >
    <div className='flex flex-col justify-center gap-2 p-4'>
      <div className='flex items-center justify-center gap-2 p-1'>
        <PDF className='size-8 md:size-14' />
        <CSV className='size-8 md:size-14' />
        <DOC className='size-8 md:size-14' />
      </div>
      <div className='text-base'>{t('drag-drop-text')}</div>
      <div className='opacity-70'>{t('drag-drop-hint')}</div>
    </div>
  </Dragger>
)

export default DragDropArea
